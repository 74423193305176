import React, { useState } from "react"
import { useAsyncDebounce } from "react-table"

const TableSearchFilter = ({
	preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) => {
  const [value, setValue] = useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
  	<div>
	    <label htmlFor="searchTable" className="block text-sm font-medium text-gray-700">
	    	Search estimates
			</label>
			<div className="mt-1 flex rounded-md shadow-sm">
			  <div className="relative flex flex-grow items-stretch focus-within:z-10">
			    <input
			    	value={value || ``}
			    	onChange={e => {
			        setValue(e.target.value);
			        onChange(e.target.value);
			      }}
			      type="text"
			      name="searchTable"
			      id="email"
			      className="block w-full rounded-none rounded-l-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
			    />
			  </div>
			</div>
		</div>
  )
}

export default TableSearchFilter