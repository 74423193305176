import * as React from "react"

const ResearchersTableCell = ({ PrimaryResearcher, SecondaryResearcher }) => {
	return (
		<>
			{PrimaryResearcher !== `None` && <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
	      {PrimaryResearcher}
	    </span>}
	    {SecondaryResearcher !== `None` &&
	    <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
	      {SecondaryResearcher}
	    </span>}
    </>
	)
}

export default ResearchersTableCell