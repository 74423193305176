import * as React from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { withAuthenticationRequired } from "@auth0/auth0-react"
import { useQuery } from "@tanstack/react-query"
import EstimatesTable from "../tables/estimates"
import { getResearchEstimates } from "../../util/api/path"
import Layout from "../layouts/layout"

const Estimates = () => {
  let records = []
  const { user } = useAuth0()
  const response = useQuery(['estimates'], getResearchEstimates)
  if (response.isLoading === false) {
    records = response.data.records
  }
  return (
    <Layout title="Estimates">
      {!response.isLoading ? <EstimatesTable records={records} /> : null}
    </Layout>
  )
}

export default withAuthenticationRequired(Estimates)