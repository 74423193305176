import React from "react"
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react"
import Layout from "../layouts/layout"

const loginOptions = () => {

}

const Home = () => {
  const { user } = useAuth0()
  return (
    <Layout title="Dashboard">
      <div className="body">
        <p>Hi, {user.nickname}</p>
      </div>
    </Layout>
  )
}

export default withAuthenticationRequired(Home)