import * as React from "react"
import { Router } from "@reach/router"
import Estimates from "../components/authenticated/estimates"
import Home from "../components/authenticated/home"
import Methods from "../components/authenticated/methods"
import Profile from "../components/authenticated/profile"
import Projects from "../components/authenticated/projects"
import Protocols from "../components/authenticated/protocols"
import ProtectedRoute from "../components/routes/protected"
import Sessions from "../components/authenticated/sessions"
import Settings from "../components/authenticated/settings"

const IndexPage = () => {
  return (
    <Router>
      <ProtectedRoute component={Home} path="/" />
      <ProtectedRoute component={Estimates} path="/estimates" />
      <ProtectedRoute component={Projects} path="/projects" />
      <ProtectedRoute component={Methods} path="/methods" />
      <ProtectedRoute component={Sessions} path="/sessions" />
      <ProtectedRoute component={Protocols} path="/protocols" />
      <ProtectedRoute component={Profile} path="/profile" />
      <ProtectedRoute component={Settings} path="/settings" />
    </Router>
  )
}

export default IndexPage
