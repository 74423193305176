import * as React from "react"

const ClientTableCell = ({ FirstName, LastName, Email }) => {
	const Name = [FirstName, LastName].join(` `)
	return (
		<>
			<div className="flex items-center">
        <div className="ml-4">
          <div className="font-medium text-gray-900">{Name}</div>
          <div className="text-gray-500">{Email}</div>
        </div>
      </div>
    </>
	)
}

export default ClientTableCell