import React from "react"
import { withAuthenticationRequired } from "@auth0/auth0-react"
import Layout from "../layouts/layout"

const Projects = () => {
  return (
    <Layout title="Projects">
    </Layout>
  )
}

export default withAuthenticationRequired(Projects)