import React from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { withAuthenticationRequired } from "@auth0/auth0-react"
import Layout from "../layouts/layout"

const Methods = () => {
  const { user } = useAuth0()
  return (
    <Layout title="Methods">
    </Layout>
 )
}

export default withAuthenticationRequired(Methods)