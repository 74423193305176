import React from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { withAuthenticationRequired } from "@auth0/auth0-react"
import Layout from "../layouts/layout"

const Profile = () => {
  const { user } = useAuth0()
  return (
    <Layout title="Profile">
      <p>Nickname: {user.nickname}</p>
      <p>Email: {user.email}</p>
      <pre>{JSON.stringify(user, null, 2)}</pre>
    </Layout>
 )
}

export default withAuthenticationRequired(Profile)