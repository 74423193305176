import React, { useMemo } from "react"
import { Link } from "gatsby" 
import { 
  useGlobalFilter, 
  usePagination,
  useSortBy,
  useTable 
} from "react-table"
import ResearchersTableCell from "./cells/researchers"
import ClientTableCell from "./cells/client"
import TableSearchFilter from "./filters/tablesearch"

const getData = ({ records }) => {
  return records.map(record => { 
    let fields = record.fields
    fields.id = record.id
    return fields 
  })
}

const getClassNames = ({ element, column }) => {
  if (element === `th`) {
    return `whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900`
  } else if (element === `td`) {
    return `whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6`
  }
}

const EstimatesTable = ({ records }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "Estimate ID",
        accessor: "EstimateID",
        sortType: "basic",
        Cell: cellInfo => (
          <Link to={`/estimates/${cellInfo.row.original.EstimateID.toLowerCase()}`} className="text-indigo-600 hover:text-indigo-900">
            <span>{cellInfo.row.original.EstimateID}</span>  
          </Link>    
        ),
      },
      {
        Header: "Client",
        accessor: row => [row.FirstName, row.LastName, row.Email],
        Cell: cellInfo => (
          <ClientTableCell 
            FirstName={cellInfo.row.original.FirstName}
            LastName={cellInfo.row.original.LastName}
            Email={cellInfo.row.original.Email} />     
        ),
      },
      {
        Header: "Sessions",
        accessor: "Sessions",
        sortType: "basic",
      },
      {
        Header: "Objective",
        accessor: "Objective1",
      },
      {
        Header: "Researchers",
        accessor: row => [row.PrimaryResearcher, row.SecondaryResearcher],
        Cell: cellInfo => (
          <ResearchersTableCell 
            PrimaryResearcher={cellInfo.row.original.PrimaryResearcher}
            SecondaryResearcher={cellInfo.row.original.SecondaryResearcher} />     
        ),
      },
      {
        Header: "Total",
        accessor: "TotalEstimate",
        sortType: "basic",
        Cell: cellInfo => {
          return `$` + cellInfo.row.original.TotalEstimate.toLocaleString('en-GB', {
            minimumFractionDigits: 2, 
            maximumFractionDigits: 2
          })
        }
      },
    ],
    []
  )
  const data = useMemo(() => getData({ records }), [ records ])
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable({
    columns,
    data
    }, 
    useGlobalFilter,
    useSortBy,
  )
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <TableSearchFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          >
            New Estimate
          </button>
        </div>
      </div>
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table {...getTableProps()} className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    {headerGroups.map(headerGroup => {
                      return <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => {
                          return <th {...column.getHeaderProps(column.getSortByToggleProps({ title: `Sort by ${column.Header}` }))} 
                            scope="col" 
                            className={getClassNames({ 
                              element: `th`, 
                              column: column })}>
                            {column.render("Header")}
                          </th>
                        })}
                      </tr>
                    })}
                  </thead>
                  <tbody {...getTableBodyProps()} className="divide-y divide-gray-200 bg-white">
                    {rows.map((row, i) => {
                      prepareRow(row)
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map(cell => {
                            return <td {...cell.getCellProps()} className={getClassNames({ 
                              element: `td`, 
                              column: cell.column })}>{cell.render("Cell")}</td>
                          })}
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default EstimatesTable